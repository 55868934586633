import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsWhatsapp } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import SelectField from "./selectField";
import Api from "../services/api";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";
import { AuthUserContext } from "../context";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { TokenExpired } from "../services/tokenExpired";

const SystemSetting = ({ sidebarWidth, subId, refresh }) => {
  const [EntitysubCopmanyId, setSubCopmanyId] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();

  const initialQuestionnaireValues = {
    externalSystemId: 2,
  };
  let ishbrews = localStorage.getItem("i18nextLng");
  const [showPassword, setShowPassword] = useState([]);
  const [questionnaireValues, setQuestionnaireValues] = useState({
    ...initialQuestionnaireValues,
  });
  const { businessId, setTokenExpired } = useContext(AuthUserContext);
  const [systemUpdate, setSystemUpdate] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [showResponseDialog, setShowResponseDialog] = useState(false);
  const [externalSystem, setExternalSystem] = useState();
  const [inputFieldDetails, setInputFieldDetails] = useState([]);
  const [inputFieldList, setInputFieldList] = useState([]);
  const [error, setError] = useState(false);
  const [logoIcons, setLogoIcons] = useState(null);
  const [videoLinks, setvideoLinks] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [textResponse, setTextResponse] = useState("");
  const [selectedData, setSelectedData] = useState();
  const [selectedFields, setSelectedFields] = useState();

  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 992);

  const handleWhatsAppButtonClick = () => {
    const whatsappUrl = "https://api.whatsapp.com/send?phone=972584222456";
    window.open(whatsappUrl, "_blank");
  };
  const onExternalSystemChange = (value) => {
    setInputFieldDetails();
    setInputFieldList();
    setSelectedData(value);
    setQuestionnaireValues((prev) => ({
      ...prev,
      externalSystemId: value?.value,
    }));
    const token = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (token) {
      Api.getExternalCustomizedField(value?.value, token)
        .then(async (res) => {
          setInputFieldList(res?.listInputLabelDetails);
          setLogoIcons(res?.logoIcon);
          setvideoLinks(res?.videoLink);
          setInputFieldDetails(res?.listInputLabelDetails);
        })
        .catch(async (e) => {
          console.error(e.message);

          // if (e.status === 401) {
          //   toast.error("Token has expired. Please sign in again.");
          //   history.push("/");
          // }
          if (e?.status === 401) {
            if (refreshToken) {
              await Api.refreshToken({
                authenticationToken: refreshToken,
              })
                .then(async (res) => {
                  localStorage.setItem("accessToken", res?.accessToken);
                  localStorage.setItem("refreshToken", res?.refreshToken);
                  const expiredSecond = await TokenExpired.getExpiredMinutes(
                    res?.RefreshTokenExpiredTime
                  );
                  setTokenExpired(expiredSecond);
                  Api.getExternalCustomizedField(value?.value, res?.accessToken)
                    .then(async (res) => {
                      setInputFieldList(res?.listInputLabelDetails);
                      setLogoIcons(res?.logoIcon);
                      setvideoLinks(res?.videoLink);
                      setInputFieldDetails(res?.listInputLabelDetails);
                    })
                    .catch(async (e) => {
                      toast.error(e.message ?? e);
                    });
                })
                .catch((err) => {
                  if (err?.status === 400) {
                    history.push("/");
                    toast.error(t("token.authentication-message"));
                  }
                });
            } else {
              history.push("/");
              toast.error(t("token.authentication-message"));
            }
          } else {
            // history.push("/");
            toast.error(e?.message ?? e);
          }
        });
    } else {
      toast.error(t("token.authentication-message"));
      history.push("/");
    }
  };

  const handleInputChange = (fieldName, newValue) => {
    try {
      setInputFieldDetails((prevDetails) => {
        const updatedDetails = prevDetails.map((field) => {
          if (field?.FieldLabelName === fieldName) {
            if (fieldName === "user_email") {
              if (!/\S+@\S+\.\S+/.test(newValue)) {
                setEmailError(true);
              } else {
                setEmailError(false);
              }
            }
            return {
              ...field,
              FieldLabelValue: newValue,
            };
          }
          return field;
        });
        return updatedDetails;
      });
    } catch (e) {
      console.log("e", e);
    }
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validateInputFields = () => {
    const errors = {};
    inputFieldDetails.forEach((element) => {
      if (!element.FieldLabelValue) {
        errors[element.FieldLabelName] = t("Questionnaire3.inputFieldRequired");
      } else if (
        element.FieldLabelName === "user_email" &&
        questionnaireValues?.externalSystemId === 2 &&
        !isValidEmail(element.FieldLabelValue)
      ) {
        errors[element.FieldLabelName] = "Invalid email address";
      }
    });
    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const openWhatsApp = () => {
    const whatsappURL = `https://api.whatsapp.com/send?phone=972584222456`;
    window.open(whatsappURL, "_blank");
    closeDialog();
  };

  const closeDialog = () => {
    setShowDialog(false);
    setShowResponseDialog(false);
  };

  const showPrompt = () => {
    setShowDialog(true);
  };
  const showResponsePrompt = () => {
    setShowResponseDialog(true);
  };
  const handleSUbmit = async () => {
    if (inputFieldList.length === 0) {
      setError(true);
      return false;
    }
    setError(false);
    const isValid = validateInputFields();
    if (isValid) {
      setLoading(true);
      const token = localStorage?.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");
      try {
        if (token) {
          let data = { listInputLabelDetails: [] };
          inputFieldDetails.forEach((element) => {
            data.listInputLabelDetails.push({
              FieldLabelName: element?.FieldLabelName,
              FieldLabelValue: element?.FieldLabelValue,
            });
          });
          data.ExternalSystemId = selectedData.Value
            ? selectedData.Value
            : questionnaireValues?.externalSystemId;
          data.Companyid = localStorage?.getItem("businessId")
            ? localStorage?.getItem("businessId")
            : localStorage?.getItem("mainCompanyId");

          data.Lang = ishbrews === "he" ? 2 : 1;
          data.SubCompanyId=EntitysubCopmanyId;
          await Api.SaveExternalCustomizedExternalSystemId(data, token)
            .then((response) => {
              localStorage.removeItem("accountStep");
              if (
                response?.success === false &&
                response?.systemRegisteredInuninet === false &&
                response?.validExternalsystemCredenatials === false
              ) {
                setLoading(false);
                setTextResponse(response?.textResponse);
                showResponsePrompt();
              } else if (
                response?.success === false &&
                response?.systemRegisteredInuninet === false &&
                response?.validExternalsystemCredenatials === true
              ) {
                setLoading(false);
                setTextResponse(response?.textResponse);
                showResponsePrompt();
              } else if (
                response?.success === false &&
                response?.systemRegisteredInuninet === false &&
                response?.validExternalsystemCredenatials === null
              ) {
                setLoading(false);
                showPrompt();
              } else {
                setLoading(false);
                toast.success(response?.textResponse);
                localStorage.setItem("UserName", response?.fullName);
                setSystemUpdate(true);
              }
            })
            .catch(async (e) => {
              console.log("e", e);

              if (e?.status === 401) {
                if (refreshToken) {
                  await Api.refreshToken({
                    authenticationToken: refreshToken,
                  })
                    .then(async (res) => {
                      localStorage.setItem("accessToken", res?.accessToken);
                      localStorage.setItem("refreshToken", res?.refreshToken);
                      const expiredSecond =
                        await TokenExpired.getExpiredMinutes(
                          res?.RefreshTokenExpiredTime
                        );
                      setTokenExpired(expiredSecond);
                      await Api.SaveExternalCustomizedExternalSystemId(
                        data,
                        res?.accessToken
                      )
                        .then((response) => {
                          localStorage.removeItem("accountStep");
                          localStorage.removeItem("accountStep");
                          if (
                            response?.success === false &&
                            response?.systemRegisteredInuninet === false &&
                            response?.validExternalsystemCredenatials === false
                          ) {
                            setLoading(false);
                            setTextResponse(response?.textResponse);
                            showResponsePrompt();
                          } else if (
                            response?.success === false &&
                            response?.systemRegisteredInuninet === false &&
                            response?.validExternalsystemCredenatials === true
                          ) {
                            setLoading(false);
                            setTextResponse(response?.textResponse);
                            showResponsePrompt();
                          } else if (
                            response?.success === false &&
                            response?.systemRegisteredInuninet === false &&
                            response?.validExternalsystemCredenatials === null
                          ) {
                            setLoading(false);
                            showPrompt();
                          } else {
                            setLoading(false);
                            toast.success(response?.textResponse);
                            localStorage.setItem(
                              "UserName",
                              response?.fullName
                            );
                            setSystemUpdate(true);
                          }
                        })
                        .catch(async (e) => {
                          setLoading(false);
                          toast.error(e?.message ?? e);
                        });
                    })
                    .catch((err) => {
                      if (err?.status === 400) {
                        history.push("/");
                        toast.error(t("token.authentication-message"));
                      }
                    });
                } else {
                  toast.error(t("token.authentication-message"));
                  history.push("/");
                }
              } else {
                // history.push("/");
                setLoading(false);
                toast.error(e?.message ?? e);
              }
            });
        } else {
          toast.error(t("token.authentication-message"));
          history.push("/");
          setLoading(false);
        }
      } catch (e) {
        console.log("e", e);
        toast.error(t("token.authentication-message"));
        history.push("/");
      }
    } else {
      const firstErrorField = Object.keys(fieldErrors)[0];
      if (firstErrorField) {
        document.getElementById(firstErrorField)?.scrollIntoView();
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const containerMediaQueryStyle = {
    width: "100%",
    margin: "0",
  };

  const containerStyle = {
    width: `calc(100% - ${sidebarWidth}px)`,
    marginRight: ishbrews === "he" ? `${sidebarWidth}px` : "",
    marginLeft: ishbrews === "he" ? "" : `${sidebarWidth}px`,
  };

  useEffect(() => {
    const updatedFields = inputFieldDetails?.map((field) => {
      const updatedField = { ...field };
      if (selectedFields.hasOwnProperty(updatedField.FieldLabelName)) {
        updatedField.FieldLabelValue =
          selectedFields[updatedField.FieldLabelName];
      }
      return updatedField;
    });
    setInputFieldDetails(updatedFields);
  }, [selectedData, inputFieldList]);

  useEffect(() => {
    setInputFieldDetails();
    setInputFieldList();
    setLoading(true);
    const token = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const mainCompanyId = localStorage.getItem("mainCompanyId");
    const subCopmanyId = subId ? subId : localStorage.getItem("SubCompanyId");
    setSubCopmanyId(subCopmanyId);
    const lang = ishbrews === "he" ? 2 : 1;
    if (token) {
      Api.getExternalSystem(lang, token)
        .then(async (res) => {
          const options = Object.keys(res).map((key) => ({
            label: res[key],
            value: parseInt(key, 10),
          }));
          setExternalSystem(options);
          Api.ShowCurrentExternalSystemDetails(
            { subCopmanyId, mainCompanyId },
            token
          )
            .then(async (res) => {
              const selected = options.find(
                (i) => i.value === res.externalSystemId
              );
              setSelectedData(selected);
              setSelectedFields(res?.fields);
            })
            .catch((err) => {
              if (err?.status === 400) {
                toast.error(t("token.authentication-message"));
                history.push("/");
              } else {
                toast.error(t("token.authentication-message"));
                history.push("/");
              }
            });
        })
        .catch(async (e) => {
          if (e?.status === 401) {
            if (refreshToken) {
              await Api.refreshToken({
                authenticationToken: refreshToken,
              })
                .then(async (res) => {
                  localStorage.setItem("accessToken", res?.accessToken);
                  localStorage.setItem("refreshToken", res?.refreshToken);
                  const expiredSecond = await TokenExpired.getExpiredMinutes(
                    res?.RefreshTokenExpiredTime
                  );
                  setTokenExpired(expiredSecond);
                  Api.getExternalSystem(res?.accessToken)
                    .then(async (res) => {
                      const options = Object.keys(res).map((key) => ({
                        label: res[key],
                        value: parseInt(key, 10),
                      }));
                      setExternalSystem(options);
                      Api.ShowCurrentExternalSystemDetails(
                        { subCopmanyId, mainCompanyId },
                        localStorage.getItem("accessToken")
                      )
                        .then(async (res) => {
                          const selected = options.find(
                            (i) => i.value === res.externalSystemId
                          );

                          setSelectedData(selected);
                          setSelectedFields(res?.fields);
                        })
                        .catch((err) => {
                          if (err?.status === 400) {
                            toast.error(t("token.authentication-message"));
                            history.push("/");
                          } else {
                            toast.error(t("token.authentication-message"));
                            history.push("/");
                          }
                        });
                    })
                    .catch((e) => {
                      toast.error(e?.message ?? e);
                      history.push("/");
                    });
                })
                .catch((err) => {
                  if (err?.status === 400) {
                    toast.error(t("token.authentication-message"));
                    history.push("/");
                  }
                });
            } else {
              toast.error(t("token.authentication-message"));
              history.push("/");
            }
          } else {
            toast.error(e?.message ?? e);
          }
        });
    } else {
      toast.error(t("token.authentication-message"));
      history.push("/");
    }
  }, [subId, refresh]);

  useEffect(() => {
    if (selectedData) {
      const token = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");
      if (token) {
        Api.getExternalCustomizedField(selectedData.value, token)
          .then(async (res) => {
            setInputFieldList(res?.listInputLabelDetails);
            setLogoIcons(res?.logoIcon);
            setvideoLinks(res?.videoLink);
            setInputFieldDetails(res?.listInputLabelDetails);
            setLoading(false);
          })
          .catch(async (e) => {
            if (e?.status === 401) {
              if (refreshToken) {
                await Api.refreshToken({
                  authenticationToken: refreshToken,
                })
                  .then(async (res) => {
                    localStorage.setItem("accessToken", res?.accessToken);
                    localStorage.setItem("refreshToken", res?.refreshToken);
                    const expiredSecond = await TokenExpired.getExpiredMinutes(
                      res?.RefreshTokenExpiredTime
                    );
                    setTokenExpired(expiredSecond);
                    await Api.getExternalCustomizedField(
                      selectedData.value,
                      res?.accessToken
                    )
                      .then(async (res) => {
                        setInputFieldList(res?.listInputLabelDetails);
                        setLogoIcons(res?.logoIcon);
                        setvideoLinks(res?.videoLink);
                        setInputFieldDetails(res?.listInputLabelDetails);
                        setLoading(false);
                      })
                      .catch((err) => {
                        toast.error(err?.message ?? err);
                      });
                  })
                  .catch((err) => {
                    if (err?.status === 400) {
                      toast.error(t("token.authentication-message"));
                      history.push("/");
                    }
                  });
              } else {
                toast.error(t("token.authentication-message"));
                history.push("/");
              }
            }
            if (e?.status === 400) {
              toast.error(e?.data?.title);
              setLoading(false);
            } else {
              toast.error(e?.message ?? e);
            }
          });
      } else {
        toast.error(t("token.authentication-message"));
        history.push("/");
      }
    }
  }, [selectedData]);
  return (
    <div
      style={isScreenSmall ? containerMediaQueryStyle : containerStyle}
      className={"container-fluid p-0 "}
    >
      <div className="content bg-bg-btn rounded-md py-3 md:py-4 px-3 md:px-4 m-3">
        <div>
          <div>
            {loading && (
              <div className="loader-overlay">
                <div className="loader"></div>
              </div>
            )}
          </div>
          {systemUpdate && (
            <div
              id="custom-prompt"
              className="fixed flex items-center justify-center flex-col z-50 mx-2 md:mx-0 back-color"
            >
              <div className="bg-white rounded-lg shadow-lg max-w-[400px] p-3 ">
                <div
                  className={
                    ishbrews === "he"
                      ? "flex justify-start"
                      : "flex justify-end"
                  }
                ></div>
                <h1
                  className={
                    "text-2xl text-center text-text-color mb-2 font-medium mb-4"
                  }
                  style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
                >
                  {t("system-setting.systemUpdate")}
                </h1>
                <div
                  className={
                    ishbrews === "he"
                      ? "flex justify-start"
                      : "flex justify-end"
                  }
                >
                  <button
                    className=" bg-bg-secondary  flex gap-2 items-center text-white py-2 px-4 rounded-lg mr-2"
                    onClick={() => setSystemUpdate(false)}
                  >
                    {t("Questionnaire3.modalOkText")}
                  </button>
                </div>
              </div>
            </div>
          )}
          {showResponseDialog && (
            <div
              id="custom-prompt"
              className="fixed flex items-center justify-center flex-col z-50 back-color"
            >
              <div className="bg-white rounded-lg shadow-lg max-w-[550px] p-4 mx-2 md:mx-0">
                <div
                  className={
                    ishbrews === "he"
                      ? "flex justify-start"
                      : "flex justify-end"
                  }
                >
                  <span
                    className="cursor-pointer"
                    onClick={() => closeDialog()}
                  >
                    <RxCross2
                      size={"30px"}
                      className="text-text-color cursor-pointer font-normal"
                    />
                  </span>
                </div>
                {/* <h1
              className={
                ishbrews === "he"
                  ? "text-2xl text-right text-text-color mb-2 font-medium"
                  : "text-2xl text-text-color mb-2 font-medium"
              }
            >
              Integration issue
            </h1> */}

                <p
                  className={
                    ishbrews === "he"
                      ? "text-gray-800 text-right text-lg mb-4"
                      : "text-gray-800 text-lg mb-4"
                  }
                  style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
                >
                  {textResponse}
                </p>
                <div
                  className={
                    ishbrews === "he"
                      ? "flex justify-start"
                      : "flex justify-end"
                  }
                >
                  <button
                    className=" bg-bg-secondary  flex gap-2 items-center text-white py-2 px-4 rounded-lg mr-2"
                    onClick={() => openWhatsApp()}
                  >
                    <span>
                      <BsWhatsapp size={"30px"} />
                    </span>
                    <span>Whatsapp</span>
                  </button>
                  <button
                    className="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg"
                    onClick={() => closeDialog()}
                  >
                    {t("Questionnaire3.modalOkText")}
                  </button>
                </div>
              </div>
            </div>
          )}
          {showDialog && (
            <div
              id="custom-prompt"
              className="fixed flex items-center justify-center flex-col z-50 mx-2 md:mx-0 back-color"
            >
              <div className="bg-white rounded-lg shadow-lg max-w-[550px] p-4 ">
                <div
                  className={
                    ishbrews === "he"
                      ? "flex justify-start"
                      : "flex justify-end"
                  }
                >
                  <span
                    className="cursor-pointer"
                    onClick={() => closeDialog()}
                  >
                    <RxCross2
                      size={"30px"}
                      className="text-text-color cursor-pointer font-normal"
                    />
                  </span>
                </div>
                <h1
                  className={
                    ishbrews === "he"
                      ? "text-2xl text-right text-text-color mb-2 font-medium"
                      : "text-2xl text-text-color mb-2 font-medium"
                  }
                >
                  {t("Questionnaire3.Integrationissue")}
                </h1>

                <p
                  className={
                    ishbrews === "he"
                      ? "text-gray-800 text-right text-lg mb-4"
                      : "text-gray-800 text-lg mb-4"
                  }
                  style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
                >
                  {t("Questionnaire3.modalText")}
                </p>
                <div
                  className={
                    ishbrews === "he"
                      ? "flex justify-start"
                      : "flex justify-end"
                  }
                >
                  <button
                    className=" bg-bg-secondary  flex gap-2 items-center text-white py-2 px-4 rounded-lg mr-2"
                    onClick={() => openWhatsApp()}
                  >
                    <span>
                      <BsWhatsapp size={"30px"} />
                    </span>
                    <span>Whatsapp</span>
                  </button>
                  <button
                    className="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg"
                    onClick={() => closeDialog()}
                  >
                    {t("Questionnaire3.modalOkText")}
                  </button>
                </div>
              </div>
            </div>
          )}
          <h1 className="font-semibold text-2xl mb-[10px] text-center mt-3">
            {t("Questionnaire3.part49")}
          </h1>
          <p className="text-base font-normal text-gray-500 text-center">
            {t("Questionnaire1.pleasecheckout")} &nbsp;
            <span
              className={
                ishbrews === "he"
                  ? "cursor-pointer text-primary-color text-left flex flex-row-reverse justify-center"
                  : "cursor-pointer text-primary-color text-left"
              }
              onClick={handleWhatsAppButtonClick}
            >
              {t("Questionnaire1.part42")}
            </span>
          </p>

          <div className={ishbrews === "he" ? "row flex-row-reverse" : "row"}>
            <div
              className={
                ishbrews === "he" ? "col-md-12 text-right" : "col-md-12"
              }
            >
              <label
                htmlFor="firstNameInput"
                className="mb-[10px] text-sm font-semibold text-text-color mt-3"
              >
                {t("Questionnaire3.part50")}
                <span className="text-primary-color"> * </span>
              </label>{" "}
              <br />
              {selectedData && (
                <SelectField
                  options={externalSystem}
                  defaultValue={selectedData}
                  handleChange={onExternalSystemChange}
                  placeholder={t("Questionnaire3.selectField-placeholder")}
                  selectedData={selectedData}
                />
              )}
              {error && !questionnaireValues?.externalSystemId && (
                <span
                  className={
                    ishbrews === "he"
                      ? "text-red-600 text-right"
                      : "text-red-600"
                  }
                >
                  {t("Questionnaire1.selectAllFields")}
                </span>
              )}
            </div>
          </div>

          <div
            className={
              ishbrews === "he" ? "row flex-row-reverse  mt-2" : "row mt-2"
            }
          >
            <div
              className={
                ishbrews === "he" ? "col-md-12 text-right" : "col-md-12"
              }
            ></div>
          </div>

          <div className={ishbrews === "he" ? "row flex-row-reverse" : "row"}>
            <div
              className={
                ishbrews === "he" ? "col-md-12 text-right" : "col-md-12"
              }
            >
              {logoIcons && (
                <div className="mt-4 ">
                  {ishbrews === "he" ? (
                    <div className="mt-2 flex flex-row-reverse ">
                      <img src={logoIcons} alt="logoIcon" className="mx-auto" />
                    </div>
                  ) : (
                    <div className="mt-2 mx-auto">
                      <img src={logoIcons} alt="logoIcon" className="mx-auto" />
                    </div>
                  )}
                </div>
              )}

              {inputFieldList &&
                inputFieldList?.map((item, idx) => {
                  return (
                    <div>
                      <div key={idx}>
                        <label
                          htmlFor="firstNameInput"
                          className="mb-[10px] text-sm font-semibold text-text-color "
                        >
                          {item?.FieldLabelName}
                          <span className="text-primary-color"> * </span>
                        </label>
                        <div
                          className={
                            ishbrews === "he"
                              ? "flex  flex-row-reverse items-center justify-between w-full px-2  py-[6px]  mb-[10px] text-lg font-medium leading-normal text-gray-900 bg-white border border-solid rounded-lg appearance-none border-bg-border bg-clip-padding"
                              : "flex  items-center  justify-between w-full px-2  py-[6px]  mb-[10px] text-lg font-medium leading-normal text-gray-900 bg-white border border-solid rounded-lg appearance-none border-bg-border bg-clip-padding"
                          }
                        >
                          <input
                            style={{
                              direction: ishbrews === "he" ? "rtl" : "ltr",
                            }}
                            className="w-full"
                            type={
                              item?.FieldTypeDesc === "Password"
                                ? showPassword[idx]
                                  ? "text"
                                  : "password"
                                : item?.FieldTypeDesc
                            }
                            required
                            defaultValue={
                              selectedFields[item?.FieldLabelName] ??
                              item.FieldLabelValue
                            }
                            onChange={(event) => {
                              handleInputChange(
                                item.FieldLabelName,
                                event.target.value
                              );
                            }}
                          />
                          {item?.FieldTypeDesc == "Password" && (
                            <div
                              onClick={() =>
                                setShowPassword((prev) => {
                                  const newArray = [...prev];
                                  newArray[idx] = !newArray[idx];
                                  return newArray;
                                })
                              }
                            >
                              {showPassword[idx] ? (
                                <AiFillEye size={25} />
                              ) : (
                                <AiFillEyeInvisible size={25} />
                              )}
                            </div>
                          )}
                        </div>
                        {fieldErrors[item.FieldLabelName] && (
                          <span className="text-red-600">
                            {t("Questionnaire3.inputFieldRequired")}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}

              {videoLinks && (
                <div className="mt-4  flex justify-center">
                  <ReactPlayer
                    url={videoLinks}
                    width="88%"
                    height="400px"
                    controls={true}
                  />
                </div>
              )}
            </div>
          </div>

          <div
            className={
              ishbrews === "he"
                ? " flex flex-row-reverse justify-between  mt-20"
                : " flex flex-row justify-between  mt-20"
            }
          >
            <button
              className=" bg-bg-secondary flex  text-base px-[22px] font-semibold items-center py-[9px] text-white rounded-md "
              onClick={handleWhatsAppButtonClick}
            >
              <div className="flex items-center gap-2">
                <span>{t("system-setting.ConnectNewSystem")}</span>
              </div>
            </button>

            <button
              onClick={() => handleSUbmit()}
              className=" bg-bg-secondary   text-base px-[22px] font-semibold flex gap-1 items-center py-[9px] text-white rounded-md "
            >
              {loading ? (
                <>
                  {ishbrews == "he" ? (
                    <>
                      <span className="">
                        ...{t("signin.Loading")}
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className=" mr-1"
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="">
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className=" mr-1"
                        />
                        {t("signin.Loading")}...
                      </span>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="flex items-center gap-2">
                    <span>{t("system-setting.update")}</span>
                  </div>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemSetting;
