import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../services/api";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { AuthUserContext } from "../context";
import { TokenExpired } from "../services/tokenExpired";
import Form from "react-bootstrap/Form";
const DocData = ({
  formValues,
  setFormValues,
  rejectData,
  setIsRejected,
  setIsEntered,
  activeKey,
  rejectedListByUser,
  setRejectedListByUser,
  clientId,
  setPdfFile,
}) => {
  let ishbrews = localStorage.getItem("i18nextLng");
  const history = useHistory();
  const { setTokenExpired } = useContext(AuthUserContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rejectLoader, setRejectLoader] = useState(false);
  // const [expenseType, setExpenseType] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedExpenseType, setSelectedExpenseType] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState(false);
  const [checkBoxValue, setCheckBoxValue] = useState(false);
  // const [percentage, setPercentage] = useState(0);
  // const [taxpercentage, setTaxPercentage] = useState(0);
  const [expenseTypeList, setExpenseTypeList] = useState([]);
  const token = localStorage?.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const [permanentProperty, setPermanent_property] = useState(false);
  const [expenseTypeName,setExpenseTypeName] = useState("")
  const [permanetProperty,setPermanentProperty] = useState(false)
  const [modalValue, setModalValue] = useState({
    tax_recognized: "",
    VAT_recognized: "",
    new_expense_text: "",
  });
  const [noVat, setNoVat] = useState(false);
  const [inputErrors, setInputErrors] = useState({
    VAT_recognized: false,
    tax_recognized: false,
  });

  useEffect(() => {
    if (formValues.expenseTypeList) {
      formValues.expenseTypeList.map((element) => {
        if (element.isDefault == true) {
          setSelectedExpenseType(element.expenseTypeId);
        }
      });
    }
  }, [formValues]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalValue({ ...modalValue, [name]: value });

    if (value !== "" && (parseFloat(value) < 0 || parseFloat(value) > 100)) {
      setInputErrors({ ...inputErrors, [name]: true });
    } else {
      setInputErrors({ ...inputErrors, [name]: false });
    }
  };
  const closeDialog = () => {
    setShowDialog(false);
    setInputErrorMessage(false);
    setModalValue({
      VAT_recognized: "",
      tax_recognized: "",
      new_expense_text: "",
    });
    setInputErrors({
      VAT_recognized: false,
      tax_recognized: false,
    });
  };
  const closeDialogValidation = async () => {
    try {
      if (
        !modalValue?.tax_recognized ||
        (!modalValue?.VAT_recognized && !noVat) ||
        !modalValue?.new_expense_text ||
        inputErrors?.VAT_recognized === true ||
        inputErrors?.tax_recognized === true
      ) {
        setInputErrorMessage(true);
        return false;
      }
      setPermanentProperty(permanentProperty)
      setExpenseTypeName(modalValue?.new_expense_text)
      setInputErrorMessage(false);
      setIsLoading(true);
      let InternalId = localStorage.getItem("businessId");
      let supplierID = localStorage.getItem("supplier_ID");
      let data = {
        vat_to_expense: true,
        expense_type_name: modalValue?.new_expense_text,
        deductable_vat: noVat ? "0" : modalValue?.VAT_recognized,
        deductable_expense: modalValue?.tax_recognized,
        internalCompanyId: InternalId,
        supplier_ID: supplierID,
        Lang: ishbrews === "he" ? 2 : 1,
        tax_id: clientId,
        permanent_property: permanentProperty,
        no_vat: noVat,
      };
      if (token) {
        await Api.addexpenseType(data, token)
          .then((response) => {
            if (response?.expenseTypeList.length > 0) {
              setExpenseTypeList(response?.expenseTypeList);
              response.expenseTypeList.forEach((element) => {
                if (element?.expenseTypeDesc === formValues?.new_expense_text) {
                  setSelectedExpenseType(element?.expenseTypeId);
                }
              });
              toast.success(response?.textResponse);
              setIsLoading(false);
              setShowDialog(false);
              setModalValue({
                VAT_recognized: "",
                tax_recognized: "",
                new_expense_text: "",
              });
              setPermanent_property(false)
              setNoVat(false);
            } else {
              setIsLoading(false);
              toast.error(response?.textResponse);
            }
          })
          .catch(async (error) => {
            if (error?.status === 401) {
              if (refreshToken) {
                await Api.refreshToken({
                  authenticationToken: refreshToken,
                })
                  .then(async (res) => {
                    localStorage.setItem("accessToken", res?.accessToken);
                    localStorage.setItem("refreshToken", res?.refreshToken);
                    const expiredSecond = await TokenExpired.getExpiredMinutes(
                      res?.RefreshTokenExpiredTime
                    );
                    setTokenExpired(expiredSecond);
                    await Api.addexpenseType(data, res?.accessToken)
                      .then((response) => {
                        setIsLoading(false);
                        if (response?.expenseTypeList.length > 0) {
                          setExpenseTypeList(response?.expenseTypeList);
                          response.expenseTypeList.forEach((element) => {
                            if (
                              element?.expenseTypeDesc ===
                              formValues?.new_expense_text
                            ) {
                              setSelectedExpenseType(element?.expenseTypeId);
                            }
                          });
                          toast.success(response?.textResponse);
                          setIsLoading(false);
                          setShowDialog(false);
                          setModalValue({
                            VAT_recognized: "",
                            tax_recognized: "",
                            new_expense_text: "",
                          });
                          setPermanent_property(false)
                          setNoVat(false);
                        } else {
                          setIsLoading(false);
                          toast.error(response?.textResponse);
                        }
                      })
                      .catch((err) => {
                        setIsLoading(false);
                        toast.error(err?.message ?? err);
                      });
                  })
                  .catch((err) => {
                    if (err?.status === 400) {
                      toast.error(t("token.authentication-message"));
                      history.push("/");
                    }
                  });
              } else {
                toast.error(t("token.authentication-message"));
                history.push("/");
              }
            } else {
              toast.error(error?.message ?? error);
              setIsLoading(false);
            }
          });
      } else {
        toast.error(t("token.authentication-message"));
        history.push("/");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const showPrompt = () => {
    setShowDialog(true);
  };
  const handleSubmit = async () => {
    if (selectedExpenseType === "") {
      setErrorMessage(true);
      return false;
    }
    let data = {
      supplier_ID: formValues?.supplier_ID,
      expense_type_id: selectedExpenseType,
      expense_doctype: formValues?.doctype,
      expense_docnum: formValues?.docNumber,
      internalCompanyId: formValues?.internalCompanyId,
      expense_sum: formValues?.amountAV,
      Jsondocumentid: formValues?.jsondocumentid,
      Lang: ishbrews === "he" ? 2 : 1,
      expense_vat_sum: formValues?.vat,
      expense_net_sum: formValues?.amountBeforeVat,
      tax_id: clientId,
    };
    try {
      if (token) {
        setLoading(true);
        await Api.ApproveDoc(data, token)
          .then((response) => {
            if (response?.status === true) {
              toast.success(response?.textResponse);
              setPdfFile([]);
              setFormValues({
                supplier_name_Sender: "",
                taxId: "",
                docNumber: "",
                doctype: "",
                currencyName: "",
                currenctRateValue: "",
                amountAV: "",
                docDate: "",
              });
              const getUniqueData = rejectedListByUser.filter(
                (item) => item?.jsonDocumentid !== data?.Jsondocumentid
              );
              setRejectedListByUser(getUniqueData);
              setIsEntered(true);
            } else {
              toast.error(response?.textResponse);
            }
            setLoading(false);
          })
          .catch(async (e) => {
            if (e?.status === 401) {
              if (refreshToken) {
                await Api.refreshToken({
                  authenticationToken: refreshToken,
                })
                  .then(async (res) => {
                    localStorage.setItem("accessToken", res?.accessToken);
                    localStorage.setItem("refreshToken", res?.refreshToken);
                    const expiredSecond = await TokenExpired.getExpiredMinutes(
                      res?.RefreshTokenExpiredTime
                    );
                    setTokenExpired(expiredSecond);
                    await Api.ApproveDoc(data, res?.accessToken)
                      .then((response) => {
                        if (response?.success === true) {
                          setPdfFile([]);
                          setFormValues({
                            supplier_name_Sender: "",
                            taxId: "",
                            docNumber: "",
                            doctype: "",
                            currencyName: "",
                            currenctRateValue: "",
                            amountAV: "",
                            docDate: "",
                          });
                          const getUniqueData = rejectedListByUser.filter(
                            (item) =>
                              item?.jsonDocumentid !== data?.Jsondocumentid
                          );
                          setRejectedListByUser(getUniqueData);
                          toast.success(response?.textResponse);
                          setIsEntered(true);
                        } else {
                          toast.error(response?.textResponse);
                        }
                        setLoading(false);
                      })
                      .catch((err) => {
                        toast.error(err?.message ?? err);
                      });
                  })
                  .catch((err) => {
                    if (err?.status === 400) {
                      toast.error(t("token.authentication-message"));
                      history.push("/");
                    }
                  });
              } else {
                toast.error(t("token.authentication-message"));
                history.push("/");
              }
            }
            if (e?.status === 400) {
              toast.error(e?.data?.title);
              setLoading(false);
            } else {
              toast.error(e?.message ?? e);
            }
          });
      } else {
        toast.error(t("token.authentication-message"));
        history.push("/");
      }
    } catch (e) {
      setLoading(false);
      console.log("e", e);
    }
  };
  // let toastDisplayed = false;
  const handleReject = async () => {
    let data = { ...rejectData, Lang: ishbrews === "he" ? 2 : 1 };
    try {
      if (token) {
        setRejectLoader(true);
        await Api.RejectDoc(data, token)
          .then((response) => {
            if (response?.success === true) {
              setIsRejected(true);
              toast.success(response?.textResponse);
              setPdfFile([]);
              setFormValues({
                supplier_name_Sender: "",
                taxId: "",
                docNumber: "",
                doctype: "",
                currencyName: "",
                currenctRateValue: "",
                amountAV: "",
                docDate: "",
              });
            } else {
              toast.error(response?.textResponse);
            }
            setRejectLoader(false);
          })
          .catch(async (e) => {
            if (e?.status === 401) {
              if (refreshToken) {
                await Api.refreshToken({
                  authenticationToken: refreshToken,
                })
                  .then(async (res) => {
                    localStorage.setItem("accessToken", res?.accessToken);
                    localStorage.setItem("refreshToken", res?.refreshToken);
                    const expiredSecond = await TokenExpired.getExpiredMinutes(
                      res?.RefreshTokenExpiredTime
                    );
                    setTokenExpired(expiredSecond);
                    await Api.RejectDoc(data, res?.accessToken)
                      .then((response) => {
                        if (response?.success === true) {
                          setPdfFile([]);
                          setFormValues({
                            supplier_name_Sender: "",
                            taxId: "",
                            docNumber: "",
                            doctype: "",
                            currencyName: "",
                            currenctRateValue: "",
                            amountAV: "",
                            docDate: "",
                          });
                          setIsRejected(true);
                          toast.success(response?.textResponse);
                        } else {
                          toast.error(response?.textResponse);
                        }
                        setRejectLoader(false);
                      })
                      .catch((err) => {
                        toast.error(err?.message ?? err);
                      });
                  })
                  .catch((err) => {
                    if (err?.status === 400) {
                      toast.error(t("token.authentication-message"));
                      history.push("/");
                    }
                  });
              } else {
                toast.error(t("token.authentication-message"));
                history.push("/");
              }
            }
            if (e?.status === 400) {
              toast.error(e?.data?.title);
              setRejectLoader(false);
            } else {
              toast.error(e?.message ?? e);
            }
          });
      } else {
        toast.error(t("token.authentication-message"));
        history.push("/");
      }
    } catch (e) {
      setRejectLoader(false);
      console.log("e", e);
    }
  };
  useEffect(() => {
    if (expenseTypeList.length) {
      let filterName = expenseTypeName;
      if (permanetProperty && !expenseTypeName.includes(" (P)")) {
        filterName += " (P)";
      }
      const newRecords = expenseTypeList.filter(item => {
        return item.expenseTypeDesc === filterName;
      });
      if (newRecords.length > 0) {
        setSelectedExpenseType(newRecords[0].expenseTypeId);

      }
    }
  }, [expenseTypeList, expenseTypeName, permanetProperty]);
  return (
    <>
      {showDialog && (
        <div
          id="custom-prompt"
          className="fixed  flex items-center justify-center  p-0 m-0 flex-col z-50 back-color  "
        >
          <div className="bg-white rounded-lg shadow-lg max-w-[550px]  p-4 border mx-2 md:mx-0">
            <div
              className={`align-start flex justify-between items-center ${
                ishbrews === "he" ? "flex-row-reverse" : "flex-row"
              }`}
            >
              <h1
                className={
                  ishbrews === "he"
                    ? "text-text-color text-xl md:text-2xl font-medium mb-1 text-right"
                    : "text-text-color text-xl md:text-2xl font-medium mb-1"
                }
              >
                {t("main-console.addNewExpense")}
              </h1>
              <div
                className={
                  ishbrews === "he" ? "flex justify-start" : "flex justify-end"
                }
              >
                <span className="cursor-pointer" onClick={() => closeDialog()}>
                  <RxCross2
                    size={"25px"}
                    className="text-text-color cursor-pointer font-normal"
                  />
                </span>
              </div>
            </div>
            <div
              className={
                ishbrews === "he"
                  ? "row flex-wrap flex-row-reverse pt-2"
                  : "row flex-wrap flex-row pt-2"
              }
              // style={{ rowGap: "20px" }}
            >
              <div className="col-md-12 col-sm-12 mt-4">
                <input
                  className={
                    ishbrews === "he"
                      ? "Amount-input-data  w-full  px-2 text-xs  py-[12px] md:text-base text-right font-normal leading-normal text-text-color   bg-white border border-solid appearance-none rounded-md border-[#D8D8D8]"
                      : "Amount-input-data  w-full  px-2 text-xs  py-[12px] md:text-base font-normal leading-normal text-text-color   bg-white border border-solid appearance-none rounded-md border-[#D8D8D8]"
                  }
                  name="new_expense_text"
                  value={modalValue?.new_expense_text}
                  onChange={(e) => handleModalChange(e)}
                  placeholder={t("main-console.part75")}
                />
                {inputErrorMessage && !modalValue?.new_expense_text && (
                  <span
                    className={
                      ishbrews === "he"
                        ? "text-red-600 text-sm flex justify-end items-center mt-1 "
                        : "text-red-600 text-sm "
                    }
                  >
                    {" "}
                    {t("main-console.Expensetypeisrequired")}
                  </span>
                )}
              </div>
              {/* <div className="col-md-12 col-sm-12 mt-4">
                <select
                  id="expenseType"
                  className={
                    ishbrews === "he"
                      ? "w-full  px-2 text-xs py-[14px] md:py-[12px] md:text-base text-right   font-normal leading-normal text-[#9CA3AF]   bg-white border border-solid appearance-none rounded-md  border-[#D8D8D8] "
                      : "w-full px-2 text-xs py-[14px] md:py-[12px] md:text-base  font-normal leading-normal text-[#9CA3AF]   bg-white border border-solid appearance-none rounded-md  border-[#D8D8D8] "
                  }
                  value={selectedExpenseType}
                  onChange={(e) => setSelectedExpenseType(e.target.value)}
                >
                  <option value="" selected>
                    {t("main-console.sortCode")}
                  </option>
                 <option value="900">
                  [900]
                 </option>
                </select>
              </div> */}
              <div className="col-md-12 col-sm-12 mt-4">
                <p
                  className={
                    ishbrews === "he"
                      ? "comment-form-cookies-consent flex flex-row-reverse  text-sm md:text-base gap-2 items-center"
                      : "comment-form-cookies-consent text-[#9CA3AF]  text-sm md:text-base  flex items-center"
                  }
                >
                  <input
                    id="wp-comment-cookies-consent"
                    name="wp-comment-cookies-consent"
                    type="checkbox"
                    value={noVat}
                    onChange={() => {
                      setNoVat(!noVat);
                      setModalValue({
                        ...modalValue,
                        VAT_recognized: "",
                      });
                    }}
                    className="expense-checkbox"
                  />
                  <label htmlFor="wp-comment-cookies-consent">
                    {t("main-console.expenseVAT")}
                  </label>
                </p>
              </div>
              {!noVat && (
                <div className="col-md-6 col-sm-12 mt-2">
                  <div
                    className={`Amount-input-data flex w-full px-2 text-xs md:text-base font-normal leading-normal text-text-color bg-white border border-solid appearance-none rounded-md border-[#D8D8D8] ${
                      ishbrews === "he" ? "flex-row-reverse" : "flex-row"
                    }`}
                  >
                    <input
                      className={`w-10/12 py-[12px]  ${
                        ishbrews === "he" ? "text-right" : "text-left"
                      }`}
                      name="VAT_recognized"
                      value={modalValue?.VAT_recognized}
                      onChange={(e) => handleModalChange(e)}
                      placeholder={t("main-console.deductableVAT")}
                      type="number"
                      min={0}
                      max={100}
                    />
                    <div
                      className={`w-2/12 py-[10px] text-[#9CA3AF] text-xl ${
                        ishbrews === "he" ? "text-start" : "text-end"
                      }`}
                    >
                      %
                    </div>
                  </div>

                  {inputErrorMessage && !modalValue?.VAT_recognized ? (
                    <span
                      className={
                        ishbrews === "he"
                          ? "text-red-600 text-sm flex justify-end items-center mt-1 "
                          : "text-red-600 text-sm "
                      }
                    >
                      {t("main-console.VATisrequired")}
                    </span>
                  ) : inputErrors?.VAT_recognized ? (
                    <span
                      className={
                        ishbrews === "he"
                          ? "text-red-600 text-sm flex justify-end items-center mt-1 "
                          : "text-red-600 text-sm "
                      }
                    >
                      {t("main-console.VATismustbe0to100")}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              )}

              <div className="col-md-6 col-sm-12 mt-2">
                <div
                  className={`Amount-input-data flex w-full px-2 text-xs md:text-base font-normal leading-normal text-text-color bg-white border border-solid appearance-none rounded-md border-[#D8D8D8] ${
                    ishbrews === "he" ? "flex-row-reverse" : "flex-row"
                  }`}
                >
                  <input
                    className={`w-11/12 py-[12px]  ${
                      ishbrews === "he" ? "text-right" : "text-left"
                    }`}
                    name="tax_recognized"
                    value={modalValue?.tax_recognized}
                    onChange={(e) => handleModalChange(e)}
                    placeholder={t("main-console.deductableIncomeTax")}
                    type="number"
                    min={0}
                    max={100}
                  />
                  <div
                    className={`w-1/12 py-[10px] text-[#9CA3AF] text-xl ${
                      ishbrews === "he" ? "text-start" : "text-end"
                    }`}
                  >
                    %
                  </div>
                </div>

                <p
                  className={`text-sm text-[#9CA3AF]  pt-1 ${
                    ishbrews === "he" ? "text-end pe-2" : "text-start ps-2"
                  }`}
                >
                  {t("main-console.taxReturn")}
                  <br />
                  {t("main-console.PleaseConsultWithYourCPA")}
                </p>
                {inputErrorMessage && !modalValue?.tax_recognized ? (
                  <p
                    className={
                      ishbrews === "he"
                        ? "text-red-600 text-sm flex justify-end items-center mt-1 "
                        : "text-red-600 text-sm "
                    }
                  >
                    {t("main-console.Taxisrequired")}
                  </p>
                ) : inputErrors?.tax_recognized ? (
                  <p
                    className={
                      ishbrews === "he"
                        ? "text-red-600 text-sm flex justify-end items-center mt-1 "
                        : "text-red-600 text-sm "
                    }
                  >
                    {" "}
                    {t("main-console.Taxismustbe0to100")}
                  </p>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-12 col-sm-12 mt-2">
                <p
                  className={`flex flex-wrap pt-2 ${
                    ishbrews === "he" ? "flex-row-reverse" : "flex-row"
                  }`}
                >
                  {ishbrews === "he" ? (
                    <>
                      <Form.Switch
                        type="switch"
                        id="custom-switch"
                        className="switch-input"
                        value={permanentProperty}
                        onChange={() =>
                          setPermanent_property(!permanentProperty)
                        }
                      />
                      <Form.Label htmlFor="custom-switch" className="mb-0 me-2">
                        {t("main-console.permanentProperty")}
                      </Form.Label>
                    </>
                  ) : (
                    <>
                      <Form.Switch
                        type="switch"
                        id="custom-switch"
                        className="switch-input"
                        value={permanentProperty}
                        onChange={() =>
                          setPermanent_property(!permanentProperty)
                        }
                      />
                      <Form.Label
                        htmlFor="custom-switch"
                        className="mb-0 ms-2"
                        style={{ marginTop: "4px" }}
                      >
                        {t("main-console.permanentProperty")}
                      </Form.Label>
                    </>
                  )}
                </p>
              </div>
            </div>
            <div
              className={
                ishbrews === "he"
                  ? "row flex-wrap flex-row-reverse pt-4 justify-center"
                  : "row flex-wrap flex-row pt-4 justify-center"
              }
              style={{ rowGap: "20px" }}
            >
              <div className="col-md-6 col-sm-12">
                <button
                  className="bg-bg-secondary py-[11px] w-full text-white text-xs md:text-base rounded-md"
                  onClick={closeDialogValidation}
                >
                  {isLoading ? (
                    <>
                      {ishbrews == "he" ? (
                        <>
                          <span className="">
                            ...{t("signin.Loading")}
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className=" mr-1"
                            />
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="">
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className=" mr-1"
                            />
                            {t("signin.Loading")}...
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>{t("main-console.AddExpenseType")}</>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="w-full md:w-1/4 bg-white rounded-md shadow-md p-3 md:p-4 h-full">
        <div>
          {(loading || rejectLoader) && (
            <div className="loader-overlay">
              <div className="loader"></div>
            </div>
          )}
        </div>
        <h1 className=" font-semibold text-2xl underline text-text-color text-center mt-0 md:mt-3 mb-4">
          {t("main-console.rightSidebarHeading")}
        </h1>
        <div className=" doc-inline flex flex-col gap-[15px]">
          {/* expense row */}
          <div
            className={
              ishbrews === "he"
                ? "row flex-wrap flex-row-reverse"
                : "row flex-wrap "
            }
            style={{ rowGap: "20px" }}
          >
            <div className="col-md-6 col-sm-12">
              <label
                className={
                  ishbrews === "he"
                    ? "text-sm font-semibold flex justify-end"
                    : "text-sm font-semibold "
                }
              >
                {t("main-console.part68")}
              </label>
              <input
                type="text"
                className={
                  ishbrews === "he"
                    ? "Amount-input-data w-full px-2 text-xs py-[12px] md:text-base  font-normal leading-normal text-text-color text-right   bg-white border border-[#D8D8D8]      rounded-md appearance-none  "
                    : "Amount-input-data w-full  px-2 text-xs py-[12px] md:text-base  font-normal leading-normal text-text-color   bg-white border border-[#D8D8D8]   appearance-none rounded-md "
                }
                id="supplierName"
                name="supplier_name_Sender"
                value={formValues?.supplier_name_Sender}
                onChange={(e) => handleChange(e)}
                placeholder={t("main-console.part68")}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <label
                className={
                  ishbrews === "he"
                    ? "text-sm font-semibold flex justify-end"
                    : "text-sm font-semibold "
                }
              >
                {t("main-console.part69")}
              </label>
              <input
                className={
                  ishbrews === "he"
                    ? "Amount-input-data  w-full  px-2 text-xs py-[12px] md:text-base text-right font-normal leading-normal text-text-color   bg-white border border-solid appearance-none rounded-md border-[#D8D8D8]"
                    : "Amount-input-data  w-full  px-2 text-xs py-[12px] md:text-base     font-normal leading-normal text-text-color   bg-white border border-solid appearance-none rounded-md border-[#D8D8D8]"
                }
                id="taxId"
                name="taxId"
                value={formValues?.taxId}
                onChange={(e) => handleChange(e)}
                placeholder={t("main-console.part69")}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <label
                className={
                  ishbrews === "he"
                    ? "text-sm font-semibold flex justify-end"
                    : "text-sm font-semibold "
                }
              >
                {t("main-console.part70")}
              </label>
              <input
                type="number"
                className={
                  ishbrews === "he"
                    ? "w-full  px-2 text-right text-xs py-[12px] md:text-base font-normal leading-normal text-text-color   bg-white border border-solid appearance-none rounded-md border-[#D8D8D8] "
                    : "w-full  px-2 text-xs py-[12px] md:text-base font-normal leading-normal text-text-color   bg-white border border-solid appearance-none rounded-md border-[#D8D8D8] "
                }
                id="docNumber"
                name="docNumber"
                value={formValues?.docNumber}
                onChange={(e) => handleChange(e)}
                placeholder={t("main-console.part70")}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <label
                className={
                  ishbrews === "he"
                    ? "text-sm font-semibold flex justify-end"
                    : "text-sm font-semibold "
                }
              >
                {t("main-console.part71")}
              </label>
              <input
                type="text"
                className={
                  ishbrews === "he"
                    ? "w-full px-2  text-xs py-[12px] md:text-base    font-normal leading-normal text-text-color   bg-white border text-right border-solid border-[#D8D8D8]  appearance-none rounded-md "
                    : "w-full px-2  text-xs py-[12px] md:text-base    font-normal leading-normal text-text-color   bg-white border border-solid border-[#D8D8D8]  appearance-none rounded-md "
                }
                id="docType"
                name="doctype"
                value={formValues?.doctype}
                onChange={(e) => handleChange(e)}
                placeholder={t("main-console.part71")}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <label
                className={
                  ishbrews === "he"
                    ? "text-sm font-semibold flex justify-end"
                    : "text-sm font-semibold "
                }
              >
                {t("main-console.part72")}
              </label>
              <input
                type="text"
                className={
                  ishbrews === "he"
                    ? "w-full  px-2  text-xs py-[12px] md:text-base    font-normal leading-normal text-text-color text-right bg-white border border-solid appearance-none rounded-md border-[#D8D8D8] "
                    : "w-full  px-2  text-xs py-[12px] md:text-base    font-normal leading-normal text-text-color bg-white border border-solid appearance-none rounded-md border-[#D8D8D8] "
                }
                name="docDate"
                value={formValues?.docDate?.slice(0, 10)}
                onChange={(e) => handleChange(e)}
                placeholder={t("main-console.part72")}
              />
            </div>
          </div>
          <hr className="hidden md:block" />

          {/* expence row */}
          <div
            className={
              ishbrews === "he"
                ? "row flex-wrap flex-row-reverse  "
                : "row flex-wrap  "
            }
            style={{ rowGap: "20px" }}
          >
            <div className="col-md-6 col-sm-12">
              <label
                className={
                  ishbrews === "he"
                    ? "text-sm font-semibold flex justify-end"
                    : "text-sm font-semibold "
                }
              >
                {t("main-console.part78")}
              </label>
              <input
                type="text"
                className={
                  ishbrews === "he"
                    ? "w-full  px-2 text-xs py-[12px] md:text-base   text-right  font-normal leading-normal text-text-color   bg-white border border-[#D8D8D8] appearance-none rounded-md "
                    : "w-full  px-2 text-xs py-[12px] md:text-base     font-normal leading-normal text-text-color   bg-white border border-[#D8D8D8] appearance-none rounded-md "
                }
                name="currencyName"
                value={formValues?.currencyName}
                onChange={(e) => handleChange(e)}
                placeholder={t("main-console.part78")}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <label
                className={
                  ishbrews === "he"
                    ? "text-sm font-semibold flex justify-end"
                    : "text-sm font-semibold "
                }
              >
                {t("main-console.part79")}
              </label>
              <input
                type="text"
                className={
                  ishbrews === "he"
                    ? "w-full px-2 text-xs py-[12px] md:text-base text-right font-normal leading-normal text-text-color   bg-white border border-solid appearance-none rounded-md border-[#D8D8D8]  "
                    : "w-full  px-2 text-xs py-[12px] md:text-base   font-normal leading-normal text-text-color   bg-white border border-solid appearance-none rounded-md border-[#D8D8D8]  "
                }
                name="currenctRateValue"
                value={formValues?.currenctRateValue}
                onChange={handleChange}
                placeholder={t("main-console.part79")}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <label
                className={
                  ishbrews === "he"
                    ? "text-sm font-semibold flex justify-end"
                    : "text-sm font-semibold "
                }
              >
                {t("main-console.part73")}
              </label>
              <input
                type="text"
                className={
                  ishbrews === "he"
                    ? "w-full  px-2 text-xs py-[12px] md:text-base  text-right   font-normal leading-normal text-text-color   bg-white border border-solid appearance-none rounded-md  border-[#D8D8D8] "
                    : "w-full px-2 text-xs py-[12px] md:text-base   font-normal leading-normal text-text-color   bg-white border border-solid appearance-none rounded-md  border-[#D8D8D8] "
                }
                id="docNumber"
                name="amountAV"
                value={formValues?.amountAV}
                onChange={handleChange}
                placeholder={t("main-console.part73")}
              />
            </div>
          </div>
          <hr className="hidden md:block" />

          <div
            className={ishbrews === "he" ? "row flex-row-reverse  " : " row   "}
          >
            <div className="col-md-9 col-sm-9 col-9">
              <label
                className={
                  ishbrews === "he"
                    ? "text-sm font-semibold flex justify-end"
                    : "text-sm font-semibold "
                }
              >
                {t("main-console.Expensetype")}
              </label>
              {expenseTypeList.length > 0 ? (
                <select
                  id="expenseType"
                  className={
                    ishbrews === "he"
                      ? "w-full  px-2 text-xs py-[14px] md:py-[12px] md:text-base text-right   font-normal leading-normal text-text-color   bg-white border border-solid appearance-none rounded-md  border-[#D8D8D8] "
                      : "w-full px-2 text-xs py-[14px] md:py-[12px] md:text-base  font-normal leading-normal text-text-color   bg-white border border-solid appearance-none rounded-md  border-[#D8D8D8] "
                  }
                  value={selectedExpenseType}
                  onChange={(e) => setSelectedExpenseType(e.target.value)}
                >
                  <option value="" disabled selected>
                    {t("main-console.Expensetype")}
                  </option>
                  {expenseTypeList?.map((item, index) => (
                    <option key={index} value={item?.expenseTypeId}>
                      {item?.expenseTypeDesc}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  id="expenseType"
                  className={
                    ishbrews === "he"
                      ? "w-full  px-2 text-xs py-[14px] md:py-[12px] md:text-base text-right   font-normal leading-normal text-text-color   bg-white border border-solid appearance-none rounded-md  border-[#D8D8D8] "
                      : "w-full px-2 text-xs py-[14px] md:py-[12px] md:text-base  font-normal leading-normal text-text-color   bg-white border border-solid appearance-none rounded-md  border-[#D8D8D8] "
                  }
                  value={selectedExpenseType}
                  onChange={(e) => setSelectedExpenseType(e.target.value)}
                >
                  <option value="" disabled selected>
                    {t("main-console.Expensetype")}
                  </option>
                  {formValues?.expenseTypeList?.map((item, index) => (
                    <option key={index} value={item?.expenseTypeId}>
                      {item?.expenseTypeDesc}
                    </option>
                  ))}
                </select>
              )}

              {errorMessage && !selectedExpenseType && (
                <span
                  className={
                    ishbrews === "he"
                      ? "text-red-600 text-sm flex justify-end"
                      : "text-red-600 text-sm flex justify-start"
                  }
                >
                  {t("main-console.Pleaseselectanexpensetype")}
                </span>
              )}
            </div>
            <div className="col-md-3 col-sm-3 col-3 mt-4">
              <button
                className="bg-bg-secondary py-[11px] px-2  flex justify-center items-center w-full text-white text-xs md:text-sm rounded-md  "
                onClick={() => {
                  if (
                    formValues?.supplier_name_Sender &&
                    formValues?.supplier_ID
                  ) {
                    setShowDialog(true);
                  }
                }}
              >
                <AiOutlinePlus size={25} />
              </button>
            </div>
          </div>
          <hr className="hidden md:block" />

          {/* expense row */}
          <div
            className={
              ishbrews === "he"
                ? "row flex-wrap mb-3 flex-row-reverse"
                : "row flex-wrap mb-3 "
            }
            style={{ rowGap: "10px" }}
          >
            <div
              className={
                activeKey === "Entered"
                  ? "col-md-12"
                  : activeKey === "Rejected"
                  ? "col-md-12"
                  : "col-md-6"
              }
            >
              {activeKey !== "Entered" && (
                <button
                  className="bg-bg-secondary py-[11px] w-full text-white text-xs md:text-base rounded-md  "
                  onClick={() => {
                    if (
                      formValues?.supplier_name_Sender &&
                      formValues?.supplier_ID
                    ) {
                      handleSubmit();
                    }
                  }}
                  disabled={
                    !formValues?.supplier_name_Sender &&
                    !formValues?.supplier_ID
                  }
                >
                  {loading ? (
                    <>
                      {ishbrews == "he" ? (
                        <>
                          <span className="">
                            ...{t("signin.Loading")}
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className=" mr-1"
                            />
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="">
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className=" mr-1"
                            />
                            {t("signin.Loading")}...
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>{t("main-console.part76")}</>
                  )}
                </button>
              )}
            </div>
            <div className="col-md-6">
              {activeKey !== "Entered" && activeKey !== "Rejected" && (
                <button
                  onClick={() => {
                    if (
                      formValues?.supplier_name_Sender &&
                      formValues?.supplier_ID
                    ) {
                      handleReject();
                    }
                  }}
                  className="bg-text-color py-[11px] w-full text-white text-xs md:text-base rounded-md "
                >
                  {rejectLoader ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        // size="20px"
                        role="status"
                        aria-hidden="true"
                        className=" mr-1"
                      />
                      <span className="">{t("signin.Loading")}...</span>
                    </>
                  ) : (
                    <> {t("main-console.part77")}</>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
        {activeKey == "Entered" && (
          <div className="mt-3">
            <p
              htmlFor="note"
              className={
                ishbrews === "he"
                  ? "text-sm font-semibold text-right "
                  : "text-sm font-semibold "
              }
            >
              {t("main-console.note")}
            </p>
          </div>
        )}
      </div>
    </>
  );
};
export default DocData;
